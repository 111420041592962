import React, { FC } from 'react'
import { ColorsTheme } from 'constants/announces/ColorTheme'
import { Content, ContentInnerCenter, Main } from './Layout.styled'
import { Header } from 'components/block/Header/Header'

export const LayoutFull: FC = ({ children }) => {
  return (
    <Main color={ColorsTheme.background}>
      <Header />
      <Content>
        <ContentInnerCenter>{children}</ContentInnerCenter>
      </Content>
    </Main>
  )
}

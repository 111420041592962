import React, { FC } from 'react'
import {
  Description,
  Image,
  Notice,
  Title,
} from 'components/page/notice/Notice.styled'
import { FlexGrow } from 'components/layout/Layout.styled'
import { FormattedMessage } from 'react-intl'
import { Indent } from 'constants/sizes'
import { LayoutFull } from 'components/layout/LayoutFull'
import { NotFoundSvg } from 'components/page/notice/icons/NotFoundSvg'
import { Spacer } from 'components/presentational/spacer'

const NotFoundResult: FC = () => {
  return (
    <LayoutFull>
      <Notice>
        <FlexGrow />
        <Spacer height={Indent.indent20} />
        <Image>
          <NotFoundSvg />
        </Image>
        <Spacer height={Indent.indent32} />
        <Title data-name="title-notice-not-found">
          <FormattedMessage
            id="not.found.search.result.title"
            defaultMessage="С такими параметрами нет объявлений..."
          />
        </Title>
        <Spacer height={Indent.indent20} />
        <Description data-name="description-notice-not-found">
          <FormattedMessage
            id="not.found.search.result.description"
            defaultMessage="Под эти параметры не подошло ни одно объявление. Попробуйте изменить настройки поиска."
          />
        </Description>
        <Spacer height={Indent.indent20} />
        <FlexGrow />
      </Notice>
    </LayoutFull>
  )
}

export default NotFoundResult
